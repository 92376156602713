import { FlowInstance } from "constants/types";
import QueueTable, { QueueTableProps } from "components/Queue/QueueTable";
import Image from "next/image";
import dayjs from "dayjs";
import useMeasure from "hooks/useMeasure";

import PaginationCards from "components/PaginationCards";
import FlowInstanceCard, {
  CardItem,
  InitiatorItem,
  ApprovalPath,
} from "components/FlowInstanceCard";
import { ReactNode } from "react";

const QueueTabUi = ({
  requests,
  pageCount,
  currentPage,
  setCurrentPage,
  loading,
  metrics,
}: { metrics: ReactNode } & QueueTableProps) => {
  const {
    ref,
    bounds: { height },
  } = useMeasure();

  // to fix the jumping
  if (loading) return null;

  const totalHeight = height ? height + 48 : 0;

  return (
    <>
      <div ref={ref} className="hidden p-24 sm:block">
        {metrics}
      </div>

      <div
        style={{
          height: `calc(100% - ${totalHeight || 210}px)`,
        }}
      >
        <QueueTable
          className="hidden sm:block"
          requests={requests}
          pageCount={pageCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          loading={loading}
        />
        <PaginationCards<FlowInstance>
          className="block sm:hidden"
          pageCount={pageCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          loading={loading}
          data={requests}
          emptyMessage={
            <>
              <div className="m-auto flex h-full  flex-grow flex-col justify-center">
                <p className="mb-24 text-center">You{`'`}re all caught up!</p>
                <Image
                  src="/illustrations/empty-queue.svg"
                  alt=""
                  width={200}
                  height={200}
                />
              </div>
            </>
          }
          renderCard={({ item }: { item: FlowInstance }) => (
            <FlowInstanceCard key={item.id} flowInstance={item}>
              <ApprovalPath flowInstance={item} isCentered />
              <InitiatorItem flowInstance={item} />
              <CardItem
                title="Response Time"
                value={dayjs(item.currentStepInstance?.activatedAt).fromNow(
                  true
                )}
              />
              <CardItem
                title="Initiated"
                value={dayjs(item.createdAt).fromNow()}
              />
            </FlowInstanceCard>
          )}
        />
      </div>
    </>
  );
};

export default QueueTabUi;
