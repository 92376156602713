import QueueLayout from "components/Queue/QueueLayout";

import { FC, useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import Metrics from "components/Queue/Metrics";
import QUEUE_TABLE_DETAILS from "graphql/queries/queueTableRequestDetails.graphql";
import APPROVAL_PATH_DETAILS from "graphql/queries/approvalPathDetails.graphql";
import QueueTabUi from "components/Queue/QueueTabUi";

const GET_MY_REQUESTS = gql`
  query getMyRequests($first: Int, $offset: Int) {
    myAppUser {
      id
      pendingRequests(first: $first, offset: $offset) {
        totalCount
        nodes {
          ...queueTableRequestDetails
          ...approvalPathDetails
        }
      }
    }
  }
  ${QUEUE_TABLE_DETAILS}
  ${APPROVAL_PATH_DETAILS}
`;

const Queue: FC = () => {
  const ROWS_PER_PAGE = 30;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const { data, loading } = useQuery(GET_MY_REQUESTS, {
    variables: {
      first: ROWS_PER_PAGE,
      offset: ROWS_PER_PAGE * (currentPage - 1),
    },
  });

  useEffect(() => {
    if (data?.myAppUser.pendingRequests?.totalCount) {
      setTotalCount(data.myAppUser.pendingRequests.totalCount);
    }
  }, [data, setTotalCount]);

  const pageCount = Math.ceil(totalCount / ROWS_PER_PAGE);

  const requests = data?.myAppUser?.pendingRequests?.nodes || [];

  return (
    <QueueTabUi
      requests={requests}
      pageCount={pageCount}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      loading={loading}
      metrics={<Metrics userId={data?.myAppUser?.id} />}
    />
  );
};

const staticProps = {
  getLayout: (page: React.ReactNode) => <QueueLayout>{page}</QueueLayout>,
};

export default Object.assign(Queue, staticProps);
