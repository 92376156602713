import Metrics from "./Metrics";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { gql, useQuery } from "@apollo/client";

dayjs.extend(relativeTime);
dayjs.extend(duration);

const GET_REQUESTS = gql`
  query getRequests($userId: UUID!) {
    appUser(id: $userId) {
      id
      weekAvgResponseTime
      monthAvgResponseTime
      avgDiscountPercentage
      pendingRequests {
        totalCount
      }
    }
  }
`;

type MetericsProps = {
  userId: string;
};

const UserMetrics: React.FC<MetericsProps> = ({ userId }) => {
  const { data } = useQuery(GET_REQUESTS, { variables: { userId } });

  return (
    <Metrics
      approvalCount={data?.appUser?.pendingRequests?.totalCount}
      weekResponseTime={data?.appUser?.weekAvgResponseTime}
      monthResponseTime={data?.appUser?.monthAvgResponseTime}
      avgDiscount={data?.appUser?.avgDiscountPercentage}
      loading={data === undefined}
    />
  );
};

export default UserMetrics;
