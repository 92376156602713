import { ResourceLayout, LayoutTabs } from "components/Layout";
import { CommonHeaderButtons } from "components/Layout/ResourceLayout";

const tabs = [
  { label: "Me", path: "/" },
  { label: "My Team", path: "/queue/team" },
  { label: "Everyone", path: "/queue/everyone" },
];

const QueueLayout: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <ResourceLayout
      resource="Queue"
      tabElement={<LayoutTabs tabs={tabs} />}
      right={<CommonHeaderButtons />}
    >
      {children}
    </ResourceLayout>
  );
};

export default QueueLayout;
